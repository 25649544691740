import * as yup from 'yup';

export default yup.object().shape({
    world_count: yup.number().integer(),
    create_spoiler: yup.boolean(),
    randomize_settings: yup.boolean(),
    open_forest: yup.string(),
    open_door_of_time: yup.boolean(),
    zora_fountain: yup.string(),
    gerudo_fortress: yup.string(),
    bridge: yup.string(),
    triforce_hunt: yup.boolean(),
    logic_rules: yup.string(),
    all_reachable: yup.boolean(),
    bombchus_in_logic: yup.boolean(),
    one_item_per_dungeon: yup.boolean(),
    trials_random: yup.boolean(),
    trials: yup.number().integer(),
    no_escape_sequence: yup.boolean(),
    no_guard_stealth: yup.boolean(),
    no_epona_race: yup.boolean(),
    no_first_dampe_race: yup.boolean(),
    useful_cutscenes: yup.boolean(),
    fast_chests: yup.boolean(),
    logic_no_night_tokens_without_suns_song: yup.boolean(),
    free_scarecrow: yup.boolean(),
    start_with_rupees: yup.boolean(),
    start_with_consumables: yup.boolean(),
    starting_hearts: yup.number().integer(),
    chicken_count_random: yup.boolean(),
    chicken_count: yup.number().integer(),
    big_poe_count_random: yup.boolean(),
    big_poe_count: yup.number().integer(),
    shuffle_kokiri_sword: yup.boolean(),
    shuffle_ocarinas: yup.boolean(),
    shuffle_weird_egg: yup.boolean(),
    shuffle_gerudo_card: yup.boolean(),
    shuffle_song_items: yup.boolean(),
    shuffle_cows: yup.boolean(),
    shuffle_beans: yup.boolean(),
    entrance_shuffle: yup.string(),
    shuffle_scrubs: yup.string(),
    shopsanity: yup.string(),
    tokensanity: yup.string(),
    shuffle_mapcompass: yup.string(),
    shuffle_smallkeys: yup.string(),
    shuffle_bosskeys: yup.string(),
    shuffle_ganon_bosskey: yup.string(),
    enhance_map_compass: yup.boolean(),
    mq_dungeons_random: yup.boolean(),
    mq_dungeons: yup.number().integer(),
    disabled_locations: yup.array().of(yup.string()),
    allowed_tricks: yup.array().of(yup.string()),
    logic_earliest_adult_trade: yup.string(),
    logic_latest_adult_trade: yup.string(),
    logic_lens: yup.string(),
    starting_equipment: yup.array().of(yup.string()),
    starting_items: yup.array().of(yup.string()),
    starting_songs: yup.array().of(yup.string()),
    ocarina_songs: yup.boolean(),
    correct_chest_sizes: yup.boolean(),
    clearer_hints: yup.boolean(),
    hints: yup.string(),
    hint_dist: yup.string(),
    text_shuffle: yup.string(),
    ice_trap_appearance: yup.string(),
    junk_ice_traps: yup.string(),
    item_pool_value: yup.string(),
    damage_multiplier: yup.string(),
    starting_tod: yup.string(),
    shuffle_medigoron_carpet_salesman: yup.boolean(),
    shuffle_interior_entrances: yup.boolean(),
    shuffle_grotto_entrances: yup.boolean(),
    shuffle_dungeon_entrances: yup.boolean(),
    shuffle_overworld_entrances: yup.boolean(),
    mix_entrance_pools: yup.boolean(),
    decouple_entrances: yup.boolean(),
    owl_drops: yup.boolean(),
    warp_songs: yup.boolean(),
    spawn_positions: yup.boolean(),
    no_first_minigame_phases: yup.boolean(),
});
